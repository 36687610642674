import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Breadcrumb,
  Card,
  Alert,
  Checkbox,
  Table,
} from "antd";
import { IoMdLock, IoMdUnlock, IoMdCloudDownload } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import {FaUserTimes} from "react-icons/fa";

// Custom imports
import "./profile.scss";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";

const ProfileAccount = () => {
  const { state, dispatch } = useContext(Context);

  const sendLogOnChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  //Log Table Content
  const dataSource = [
    {
      key: "1",
      ipAddress: "103.125.161.189",
      date: "Sep 21, 2021, 1:54:43 PM",
      status: "Success",
    },
    {
      key: "2",
      ipAddress: "202.65.133.182",
      date: "Sep 04, 2021, 12:37:57 PM",
      status: "Success",
    },
    {
      key: "3",
      ipAddress: "202.65.133.188",
      date: "Sep 01, 2021, 12:37:57 PM",
      status: "Success",
    },
  ];

  const columns = [
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("profileAccount", dispatch)}
        >
          Profile
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Account</Breadcrumb.Item>
    </Breadcrumb>
  );

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      <Row>
        <Col span="24" className="full-width page-header">
          <Row align="middle">
            <Col span="14" className="page-title ">
              Profile - <span>Account</span>
            </Col>
            <Col span="10" flex="row" align="end"></Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Account Starts */}
          <Card
            title="Account"
            extra={<Button type="default">Edit</Button>}
            className="profile-card full-width"
          >
            <ul className="list-unstyled full-width account-list-row">
              <li>
                <Row>
                  <Col span="8">Account Number:</Col>
                  <Col span="16">1101</Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Accout Name:</Col>
                  <Col span="16">
                    yugandhr.designer@ibeesolutionshosting.com
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Currency</Col>
                  <Col span="16">EUR</Col>
                </Row>
              </li>
            </ul>
          </Card>
          {/* Account Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Security Access Preferences Starts */}
          <Card
            title="Security and Access Preferences"
            extra={
              <Button type="default" className="card-edit-btn">
                <IoMdLock /> Enable TFA
              </Button>
            }
            className="profile-card full-width"
          >
            <div className="full-width">
              <Alert message="Info Text" type="info" showIcon />
            </div>
            <div className="full-width my-2">
              Allow support to log into my account for 24 hours
            </div>
            <div className="full-width">
              <Button type="primary" className="btn-success">
                Allow
              </Button>
            </div>
          </Card>
          {/* Security Access Preferences Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Authorization History Starts */}
          <Card
            title="Authorization history"
            extra=""
            style={{ width: "100%" }}
            className="profile-card"
          >
            <div className="full-width fs-6 mb-2">
              <Checkbox onChange={sendLogOnChange}>
                Send notification about each authorization attempt to
                yugandhar.designer@gmail.com
              </Checkbox>
            </div>

            <div className="full-width log-table mb-2">
              <Table dataSource={dataSource} columns={columns} />
            </div>
          </Card>
          {/* Authorization History Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Currnet User Starts */}
          <Card
            title="Current user"
            extra={
              <Button type="default" className="card-edit-btn">
                <RiLockPasswordFill /> Change Password
              </Button>
            }
            style={{ width: "100%" }}
            className="profile-card"
          >
            <ul className="list-unstyled full-width account-list-row">
              <li>
                <Row>
                  <Col span="8">Name:</Col>
                  <Col span="16">
                    yugandhr.designer@ibeesolutionshosting.com
                  </Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Account:</Col>
                  <Col span="16">1101 yugandhar.designer@gmail.com</Col>
                </Row>
              </li>
              <li>
                <Row>
                  <Col span="8">Last Login:</Col>
                  <Col span="16">Sep 21, 2021, 01:54 PM (103.125.161.189)</Col>
                </Row>
              </li>
            </ul>
            <div className="full-width mt-2">
              <Button type="default" className="btn-info me-1 text-white">
                <IoMdCloudDownload style={{ marginRight: "5px" }} /> Export
                Personal Data
              </Button>
              <Button type="primary" className="btn-danger me-1">
                <FaUserTimes style={{ marginRight: "5px" }} /> Deactivate user
              </Button>
            </div>
          </Card>
          {/* Currnet User Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Referral Program Starts */}
          <Card
            title="Referral Program"
            extra=""
            style={{ width: "100%" }}
            className="profile-card"
          >
            <div className="full-width">
              Share a free month with a friend! Refer a friend with one of this
              links and choose how much you will split. You give your friend a
              link with a coupon for ..., and once he pays ... top of that, you
              get ... too!
            </div>
            <div className="full-width my-1">
              <Row>
                <Col span="6">
                  <Input disabled className="text-dark" value="Rs.50.00 link" />
                </Col>
                <Col span="18">
                  <Input
                    disabled
                    className="text-dark"
                    value="https://portal.ibee.in"
                  />
                </Col>
              </Row>
            </div>
            <div className="full-width my-1">
              If you’re looking for a faster reward, opt in for ... coupon: your
              friend will get ..., and once he makes a ... payment, you get your
              .... Fast reward and large reward — everything is possible with
              Servers.com referral program.
            </div>
            <div className="full-width my-1">
              <Row>
                <Col span="6">
                  <Input disabled className="text-dark" value="Rs.50.00 link" />
                </Col>
                <Col span="18">
                  <Input
                    disabled
                    className="text-dark"
                    value="https://portal.ibee.in"
                  />
                </Col>
              </Row>
            </div>
          </Card>
          {/* Referral Program Ends */}
        </Col>

        <Col className="py-1 px-2" xs={24} xxl={12}>
          {/* Push Notifications Starts */}
          <Card
            title="Push notifications"
            extra=""
            style={{ width: "100%" }}
            className="profile-card"
          >
            <div className="full-width mb-1">
              Please subscribe if you want to receive push notifications from the Servers.com Customer Portal.
            </div>
            <div className="full-width">
              <Button type="primary" className="btn-success">Subscribe</Button>
            </div>
          </Card>
          {/* Push Notifications Ends */}
        </Col>
      </Row>
    </>
  );
};

export default ProfileAccount;
