import React, { useState, useContext, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Breadcrumb,
  Card,
  Radio,
  Tooltip,
  Tabs,
  Checkbox,
  Form,
} from "antd";

import { IoMdFlag, IoMdTrash } from "react-icons/io";
import {
  FaTools,
  FaRegCreditCard,
  FaExclamationTriangle,
} from "react-icons/fa";
import {
  AiOutlineStop,
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineClose,
} from "react-icons/ai";

// Custom imports
import "./profileContacts.scss";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";

const ProfileContacts = () => {
  const [showView, setShowView] = useState("list");
  const [showAddform, setShowAddform] = useState(true);

  const { state, dispatch } = useContext(Context);
  const { Search, TextArea } = Input;
  const { TabPane } = Tabs;

  //Breadcrumb Nav
  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("profileAccount", dispatch)}
        >
          Profile
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Contacts</Breadcrumb.Item>
    </Breadcrumb>
  );

  // Create Contact Form On Submit SUCCESS
  const createNewContactSuccess = () => {};

  // Create Form on Submit FAILS
  const createNewContactFailed = () => {};

  // Role Group
  const options = [
    { label: "Technical", value: "technical" },
    { label: "Billing", value: "billing" },
    { label: "Abuse", value: "abuse" },
    { label: "Emergency", value: "emergency" },
  ];

  // Role OnChange
  const roleOnChange = (checkedValues) => {
    console.log("checked = ", checkedValues);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    };
  }, []);

  return (
    <>
      {showView === "list" ? (
        <>
          {/* Contacts List Starts */}
          <Row>
            <Col span="24" className="full-width page-header">
              <Row align="middle">
                <Col span="6" className="page-title ">
                  Profile - <span>Contacts</span>
                </Col>
                <Col span="18" flex="row" align="end">
                  <Radio.Group value="All" onChange="">
                    <Radio.Button value="all">All</Radio.Button>
                    <Radio.Button value="flag">
                      <IoMdFlag />
                    </Radio.Button>
                    <Radio.Button value="technical">
                      <FaTools />
                    </Radio.Button>
                    <Radio.Button value="billing">
                      <FaRegCreditCard />
                    </Radio.Button>
                    <Radio.Button value="abuse">
                      <AiOutlineStop />
                    </Radio.Button>
                    <Radio.Button value="abuse">
                      <FaExclamationTriangle />
                    </Radio.Button>
                  </Radio.Group>
                  <Search
                    placeholder="input search text"
                    onSearch=""
                    style={{ width: 180 }}
                    className="ms-1"
                  />
                  <Button
                    type="primary"
                    className="ms-1"
                    onClick={() => setShowView("create")}
                  >
                    + Create New
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="py-1 px-2" xs={24}>
              <Row align="stretch" gutter="20">
                <Col xs={24} md={12} xxl={8} className="mb-2">
                  <Card
                    title="Shanker (Display Name Display Name)"
                    extra={
                      <>
                        <Tooltip title="Technician">
                          <FaTools className="ms-1 text-gray" />
                        </Tooltip>
                        <Tooltip title="Billing">
                          <FaRegCreditCard className="ms-1" />
                        </Tooltip>
                      </>
                    }
                    className="profile-card full-width"
                  >
                    <ul className="list-unstyled full-width account-list-row mb-0">
                      <li>
                        <Row>
                          <Col span="8">Email:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="8">Phone number:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlinePhone /> +91123456789
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li className="mt-1">
                        <Row className="w-100">
                          <Col span="6">
                            <Button
                              size="small"
                              type="ghost"
                              className="btn-outline-danger footer-icon-btn"
                            >
                              <AiOutlineDelete size={17} />
                            </Button>
                          </Col>
                          <Col
                            span="18"
                            style={{
                              display: "inline-flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                              >
                                <AiOutlineEdit size={17} />
                              </Button>
                            </Tooltip>
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                                onClick={() => setShowView("detail")}
                              >
                                <AiOutlineEye size={17} />
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Card>
                </Col>
                <Col xs={24} md={12} xxl={8} className="mb-2">
                  <Card
                    title="Primary (Display Name)"
                    extra={
                      <>
                        <Tooltip title="Technician">
                          <FaTools className="ms-1 text-gray" />
                        </Tooltip>
                        <Tooltip title="Billing">
                          <FaRegCreditCard className="ms-1" />
                        </Tooltip>
                      </>
                    }
                    className="profile-card full-width"
                  >
                    <ul className="list-unstyled full-width account-list-row mb-0">
                      <li>
                        <Row>
                          <Col span="8">Email:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                              <li className="full-width text-truncate mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="8">Phone number:</Col>
                          <Col span="16">
                            <ul className="list-unstyled full-width">
                              <li className="full-width text-truncate mb-0">
                                <AiOutlinePhone /> +91123456789
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li className="mt-1">
                        <Row className="w-100">
                          <Col span="6"></Col>
                          <Col
                            span="18"
                            style={{
                              display: "inline-flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                              >
                                <AiOutlineEdit size={17} />
                              </Button>
                            </Tooltip>
                            <Tooltip title="View Detail">
                              <Button
                                type="ghost"
                                size="small"
                                className="footer-icon-btn ms-1"
                                onClick={() => setShowView("detail")}
                              >
                                <AiOutlineEye size={17} />
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Contacts List Ends */}
        </>
      ) : null}

      {showView === "create" ? (
        <>
          {/* Create New Contact Block Starts */}
          <Row>
            <Col span="24">
              <div className="full-width bg-white">
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={createNewContactSuccess}
                  onFinishFailed={createNewContactFailed}
                  autoComplete="off"
                >
                  <Tabs
                    tabBarExtraContent={{
                      left: (
                        <div className="fs-5 tabs-left-title">
                          Add New Contact
                        </div>
                      ),
                    }}
                    className="createNewTabs full-width"
                  >
                    <TabPane tab="Basic Info" key="1">
                      <div className="full-width p-2">
                        <ul className="list-unstyled form-list full-width create-new-form">
                          <li>
                            <Form.Item
                              label="First name"
                              name="firstName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your First name!",
                                },
                              ]}
                            >
                              <Input className="" />
                            </Form.Item>
                          </li>
                          <li>
                            <Form.Item
                              label="Last name"
                              name="lastName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Last name!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </li>
                          <li>
                            <Form.Item
                              label="Email"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Email Id!",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </li>
                          <li>
                            <Form.Item
                              label="Phone Number"
                              name="phoneNumber"
                              extra="E.g.: +1 (234) 567-89-90"
                            >
                              <Input />
                            </Form.Item>
                          </li>
                          <li>
                            <Form.Item label="Role" name="role">
                              <Checkbox.Group
                                options={options}
                                onChange={roleOnChange}
                              />
                            </Form.Item>
                          </li>
                          <li>
                            <Form.Item label="Job Title" name="jobTitle">
                              <Input />
                            </Form.Item>
                          </li>
                          <li>
                            <Form.Item label="Job Role" name="jobRole">
                              <Input />
                            </Form.Item>
                          </li>
                        </ul>
                      </div>
                    </TabPane>
                    <TabPane tab="Details" key="2">
                      <div className="full-width p-2">
                        <ul className="list-unstyled form-list full-width create-new-form">
                          <li>
                            <Form.Item label="Comments" name="comments">
                              <TextArea rows={4} />
                            </Form.Item>
                          </li>
                        </ul>
                      </div>
                    </TabPane>
                  </Tabs>
                  <div className="full-width px-2 pt-2 pb-3 border-top">
                    <Button type="default" onClick={() => setShowView("list")}>
                      Cancel
                    </Button>
                    <Button
                      type="default"
                      htmlType="submit"
                      className="btn-success ms-2 px-3"
                      onClick={() => setShowView("list")}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>

          {/* Create New Contact Block Ends */}
        </>
      ) : null}

      {showView === "detail" ? (
        <>
          {/* Groups List Starts */}
          <Row>
            <Col className="p-2" xs={24}>
              <Row align="stretch" gutter="20">
                <Col span="24" className="mb-2">
                  <Card
                    title="Shanker (Display Name)"
                    className="profile-card full-width"
                    extra={
                      <>
                        <Tooltip title="Close">
                          <Button
                            type="link"
                            shape="circle"
                            className="btn-gray p-0"
                            onClick={() => setShowView("list")}
                          >
                            <AiOutlineClose size="17" />
                          </Button>
                        </Tooltip>
                      </>
                    }
                  >
                    <ul className="list-unstyled full-width account-list-row mb-0">
                      <li>
                        <Row>
                          <Col span="6">Email:</Col>
                          <Col span="18">
                            <ul className="list-unstyled full-width">
                              <li className="full-width mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                              <li className="full-width mb-0">
                                <AiOutlineMail />{" "}
                                yugandhr.designeryugandhr.designer@gmail.com
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">Phone number:</Col>
                          <Col span="18">
                            <ul className="list-unstyled full-width">
                              <li className="full-width mb-0">
                                <AiOutlinePhone /> +91123456789
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">Job Title:</Col>
                          <Col span="18">
                            <ul className="list-unstyled full-width">
                              <li className="full-width mb-0">
                                Sr. Web Designer
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row>
                          <Col span="6">Job Title:</Col>
                          <Col span="18">
                            <ul className="list-unstyled full-width">
                              <li className="full-width mb-0">Developer</li>
                            </ul>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* Groups List Ends */}
        </>
      ) : null}
    </>
  );
};

export default ProfileContacts;
