import React, {useState, useContext, useEffect} from "react";
import { Row, Col, Input, Menu, Dropdown, Button, Breadcrumb } from "antd";

import { ImSortAlphaAsc } from "react-icons/im";

// Custom imports
import {Context} from "../../context";
import { BREADCRUMB } from "../../reducer/types";
import { LoadPage } from "../../SharedFunctions";


const DedicatedServersManage = () => {

  const {state, dispatch} = useContext(Context);

  const { Search } = Input;

  const onSearch = (value) => console.log(value);

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button type="link" className="p-0" onClick={() => LoadPage("/", dispatch)}>
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        Dedicated Server - <em>Manage</em>
      </Breadcrumb.Item>
    </Breadcrumb>
  );

  useEffect(() => {

    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav
      }
    });


    return () => {
      dispatch({
        type: BREADCRUMB,
        payload: {
          breadcrumb: null,
        },
      });
    }

  }, []);


   return (
     <Row>
       <Col span="24" className="full-width page-header">
         <Row align="middle">
           <Col span="14" className="page-title">
             Dedicated Servers - <span>Manage</span>
           </Col>
           <Col span="10" flex="row" align="end">
             <Row>
               <Col span="8">
                <Button type="default">
                  <ImSortAlphaAsc style={{marginRight: '8px', color: "#666"}} /> Sort by Name
                </Button>
               </Col>
               <Col span="1">&nbsp;</Col>
               <Col span="15">
                 <Search
                   placeholder="Search..."
                   onSearch={onSearch}
                   style={{ width: "100%" }}
                 />
               </Col>
             </Row>
           </Col>
         </Row>
       </Col>
     </Row>
   );
}


export default DedicatedServersManage;