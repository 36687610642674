import { useEffect, useState, useReducer } from 'react';
import { Button, Form, Input, Row, Col, Checkbox } from "antd";
import "antd/dist/antd.css";

import { Context } from './context'
import AdminModule from './components/AdminModule'


import './App.scss';

import logo from "./assets/images/ibee-hosting-logo.png";
import { initialState, reducer } from './reducer';
import { LOGIN_SUCCESS, PAGE } from "./reducer/types";
import { LoadPage } from "./SharedFunctions";
import Layout from "antd/lib/layout/layout";

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  let apiUrl;

  if (process.env.NODE_ENV === "production") {
    apiUrl = "https://api.ibee.ai";
  } else {
    apiUrl = "http://localhost:8000";
  }

  const [signedIn, setSignedIn] = useState(false);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [formToggle, setFormToggle] = useState("showLogin");

  const [form] = Form.useForm();

  const switchForm = (val) => {
    setFormToggle(val);

    /* switch (val) {
      case "showLogin":
        setFormToggle("login");
        break;

      case "showForgotPassword":
        setFormToggle("forgotPassword");
        break;

      default:
        break;
    } */
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("access_token");
    if (token) {
      //setToken(token)
      setSignedIn(true);
    }
  }, []);

  const handleLoginSubmit = () => {
    setLoading(true);
  };

  const loginNow = () => {
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        isAuthenticated: true,
        token: "abc123",
        userId: "123abcd",
        userName: "Yugandhar",
      },
    });

    setSignedIn(true);

    console.log("Login success state - ", state);

    LoadPage("dashboard", dispatch);
  };

  if (signedIn) {
    return (
      <>
        {/* <Context.Provider value={{ apiUrl, token }}>
        <AdminModule />
      </Context.Provider> */}
        <Context.Provider value={{ state: state, dispatch: dispatch }}>
          <AdminModule />
        </Context.Provider>
      </>
    );
  } else {
    return (
      <>
        <Layout
          align="center"
          style={{ minHeight: "100vh" }}
          className="d-flex align-items-center justify-content-center full-width"
        >
          <Row className="w-100">
            <Col
              span="18"
              offset="3"
              className="bg-white shadow rounded-3"
              style={{ overflow: "hidden" }}
            >
              {/* <Row className="">
                <Col span="24">
                  <div className="full-width text-center branding-logo p-0 py-2 bg-light">
                    <img src={logo} className="logo" alt="IBEE Hosting" />
                  </div>
                </Col>
              </Row> */}
              <Row align-items="stretch" className="my-2">
                <Col span="12">
                  {formToggle === "showLogin" ? (
                    <>
                      {/* Login Starts */}
                      <div className="full-width login-block px-3 ">
                        <div className="full-width text-start branding-logo pb-2">
                          <img
                            src={logo}
                            className="logo d-inline-block"
                            alt="IBEE Hosting"
                          />
                        </div>
                        <div className="login-signup-header full-width">
                          <div className="login-signup-main-title full-width">
                            Login Now
                          </div>
                          <div className="login-signup-short-info full-width">
                            Dear user, log in to access the Self Service Panel.
                          </div>
                        </div>

                        <div className="full-width login-form-block">
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleLoginSubmit}
                            onFinishFailed={onFinishFailed}
                          >
                            <Form.Item
                              label="Email Address"
                              name="username"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Email Id!",
                                },
                              ]}
                            >
                              <Input className="form-control" />
                            </Form.Item>
                            <Form.Item
                              label="Password"
                              name="password"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your Password!",
                                },
                              ]}
                            >
                              <Input type="password" className="form-control" />
                            </Form.Item>
                            <Form.Item className="flex">
                              <div className="d-flex align-items-center justify-content-start">
                                <Button
                                  htmlType="submit"
                                  disabled={loading}
                                  className="loginBtn align-self-center btn-primary"
                                  type="primary"
                                  onClick={() => loginNow()}
                                >
                                  Login
                                </Button>
                                <span className="signup-block">
                                  or{" "}
                                  <Button
                                    type="link"
                                    className="joinNowLink"
                                    onClick={() => setFormToggle("showSignup")}
                                  >
                                    Signup{" "}
                                  </Button>
                                </span>
                              </div>
                            </Form.Item>
                            <Form.Item className="mb-0">
                              <div className="full-width d-flex justify-content-start">
                                <Button
                                  type="link"
                                  className="p-0"
                                  onClick={() =>
                                    setFormToggle("showForgotPassword")
                                  }
                                >
                                  Forgot Password?
                                </Button>
                              </div>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                      {/* Login Starts */}
                    </>
                  ) : formToggle === "showForgotPassword" ? (
                    <>
                      {/* Forgot Password Starts */}
                      <div className="full-width signup-block px-3">
                        <div className="login-signup-header full-width">
                          <div className="login-signup-main-title full-width">
                            Forgot Password
                          </div>
                          <div className="login-signup-short-info full-width">
                            To reset your password, please enter your user
                            email.
                          </div>
                        </div>

                        <div className="full-width login-form-block">
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleLoginSubmit}
                          >
                            <Form.Item label="Email Address" name="username">
                              <Input className="form-control" />
                            </Form.Item>

                            <Form.Item className="flex">
                              <div className="d-flex align-items-center justify-content-start">
                                <Button
                                  htmlType="submit"
                                  disabled={loading}
                                  className="loginBtn align-self-center btn-primary"
                                  type="primary"
                                  onClick={() => loginNow()}
                                >
                                  Send
                                </Button>
                                <span className="signup-block">
                                  or{" "}
                                  <Button
                                    type="link"
                                    className="joinNowLink"
                                    onClick={() => setFormToggle("showSignup")}
                                  >
                                    Signup{" "}
                                  </Button>
                                </span>
                              </div>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>

                      {/* Forgot Password Ends */}
                    </>
                  ) : formToggle === "showSignup" ? (
                    <>
                      {/* Signup Starts */}
                      <div className="full-width signup-block px-3">
                        <div className="login-signup-header full-width">
                          <div className="login-signup-main-title full-width">
                            Signup Now
                          </div>
                          <div className="login-signup-short-info full-width">
                            To create new account, please enter your user email
                            address.
                          </div>
                        </div>

                        <div className="full-width login-form-block">
                          <Form
                            form={form}
                            layout="vertical"
                            onFinish={handleLoginSubmit}
                          >
                            <Form.Item label="Email Address" name="username">
                              <Input className="form-control" />
                            </Form.Item>

                            <Form.Item
                              name="termsConditions"
                              className="text-start mb-1"
                            >
                              <Checkbox
                                onChange=""
                                className="justify-content-start fs-7 signupCheckbox"
                              >
                                By ticking this box you confirm that you have
                                read and understood our{" "}
                                <a href="#">Privacy policy</a>,
                                <a href="#">Website Terms of Use</a>, the
                                General Terms and Conditions and the policies at{" "}
                                <a href="#">https://www.ibee.ai/legal</a>
                              </Checkbox>
                            </Form.Item>

                            <Form.Item
                              name="newsLetter"
                              className="text-start mb-1"
                            >
                              <Checkbox
                                onChange=""
                                className="justify-content-start fs-7 signupCheckbox"
                              >
                                I want to receive a marketing newsletter
                              </Checkbox>
                            </Form.Item>

                            <Form.Item className="flex">
                              <div className="d-flex align-items-center justify-content-start">
                                <Button
                                  htmlType="submit"
                                  disabled={loading}
                                  className="loginBtn align-self-center btn-primary"
                                  type="primary"
                                  onClick={() => loginNow()}
                                >
                                  Join
                                </Button>
                                <span className="signup-block">
                                  or{" "}
                                  <Button
                                    type="link"
                                    className="joinNowLink"
                                    onClick={() => setFormToggle("showLogin")}
                                  >
                                    Login{" "}
                                  </Button>
                                </span>
                              </div>
                            </Form.Item>
                          </Form>
                        </div>
                      </div>
                      {/* Signup Ends */}
                    </>
                  ) : null}
                </Col>
                <Col span="12" className="border-start">
                  <div className="full-width px-3 text-left">
                    <ul className="login-signup-benfits list-unstyled full-width mb-0">
                      <li>
                        <div className="each-login-signup-benefit full-width">
                          <div className="each-benefit-icon"></div>
                          <div className="each-benefit-content">
                            40 Gbps private and public redundant network
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="each-login-signup-benefit full-width">
                          <div className="each-benefit-icon"></div>
                          <div className="each-benefit-content">
                            Cloud integration
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="each-login-signup-benefit full-width">
                          <div className="each-benefit-icon"></div>
                          <div className="each-benefit-content">
                            Designed for clusters
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="each-login-signup-benefit full-width">
                          <div className="each-benefit-icon"></div>
                          <div className="each-benefit-content">
                            FREE cross-continental network
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout>
      </>
    );
  }
};

export default App;
