import React, {useState, useContext, useEffect} from "react";
import {
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Button,
  message,
  Space,
  Tooltip,
  Breadcrumb
} from "antd";
import { FiUser } from "react-icons/fi";
import { IoLogOutOutline, IoCart } from "react-icons/io5";

// Custom imports
import "./header.scss";
import logo from "../../assets/images/ibee-hosting-logo.png";
import {Context} from "../../context";
import { LoadPage } from "../../SharedFunctions";


const Header = (props) => {
   const { Header, Footer, Sider, Content } = Layout;

   const { state, dispatch } = useContext(Context);

   const menu = (
     <Menu onClick="">
      <Menu.Item key="1">
        Logout
      </Menu.Item>
      <Menu.Item key="2">
        2nd menu item
      </Menu.Item>
      <Menu.Item key="3">
        3rd menu item
      </Menu.Item>
    </Menu>
   )

  return (
    <Header
      className="full-width header px-0"
      style={{ backgroundColor: "#fff" }}
    >
      <Row>
        <Col span="4">
          <div className="full-width branding-block">
            <a href="/" className="branding-link" title="IBEE Hosting">
              <img src={logo} alt="IBEE Hosting" />
            </a>
          </div>
        </Col>
        <Col span="14" className="pt-15 px-2">
          <div className="full-width">
            {state.breadcrumb ? state.breadcrumb : ""}
          </div>
        </Col>
        <Col
          span="1"
          className="d-flex align-items-center justify-content-center"
        ></Col>
        <Col span="4">
          <div className="d-flex align-items-center justify-content-end">
            <Button
              type="link"
              className="header-cart"
              onClick={() => LoadPage('cart', dispatch)}
            >
              <IoCart /> <span className="cart-count">10</span>
            </Button>
            <div className="d-">S Yugandhar</div>
          </div>
        </Col>
        <Col span="1">
          <Tooltip title="Logout">
            <Button
              className="mx-1"
              shape="circle"
              icon={<IoLogOutOutline />}
            />
          </Tooltip>
        </Col>
      </Row>
    </Header>
  );
};

export default Header;
