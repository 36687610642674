import React, {useState, useEffect, useContext} from 'react';
import { Layout, Row, Col, Result, Button, Card, BackTop } from "antd";
import { IoArrowUp } from "react-icons/io5";


// Custom imports
import Header from "../Header";
import Footer from "../Footer";
import SideNavigation from "../SideNavigation";
import DedicatedServersManage from "../DedicatedServersManage";
import DedicatedServersOrder from "../DedicatedServersOrder";
import CloudServersManage from "../CloudServersManage";
import CloudServersImages from "../CloudServersImages";
import CloudServersVolumes from "../CloudServersVolumes";
import {Context} from "../../context";
import ServerConfiguration from '../ServerConfiguration';
import Cart from '../Cart';
import ProfileAccount from '../ProfileAccount';
import Dashboard from "../Dashboard";
import ProfileContacts from "../ProfileContacts";
import ProfileGroups from "../ProfileGroups";
import ProfileSSHKeys from "../ProfileSSHKeys";
import CloudStorage from "../CloudStorage";
import Invoices from '../Invoices';
import InvoiceDetail from '../InvoiceDetail';
import BillingOrders from '../BillingOrders';

const AdminModule = () => {
  const { state, dispatch } = useContext(Context);

  const { Sider, Content } = Layout;

  const page = {
    dashboard: <Dashboard />,
    dsManage: <DedicatedServersManage />,
    dsOrder: <DedicatedServersOrder />,
    ccCreateManage: <CloudServersManage />,
    ccImages: <CloudServersImages />,
    ccVolumes: <CloudServersVolumes />,
    serverConfiguration: <ServerConfiguration />,
    cart: <Cart />,
    profileAccount: <ProfileAccount />,
    profileContacts: <ProfileContacts />,
    profileGroups: <ProfileGroups />,
    profileSSHKeys: <ProfileSSHKeys />,
    cloudStorage: <CloudStorage />,
    billingInvoices: <Invoices />,
    invoiceDetail: <InvoiceDetail />,
    billingOrders: <BillingOrders />,
  };

  const style = {
    height: 40,
    width: 40,
    lineHeight: "40px",
    borderRadius: 4,
    backgroundColor: "#1088e9",
    color: "#fff",
    textAlign: "center",
    fontSize: 14,
  };

  useEffect(() => {
    console.log("AdminModule state - ", state);

    return () => {};
  }, [state]);

  return (
    <Layout className="full-width main">
      <Header></Header>

      {/* <div className="full-width main-content-block">
        <div className="left-sidebar">
          <div className="full-width main-navigation">
            <SideNavigation />
          </div>
        </div>
        <div className="main-content">
          <Row className="bg-white">
            {page[state.page] || (
              <Result
                status="warning"
                title="You don't have permissions to view page"
                extra=""
              />
            )}
          </Row>
        </div>
      </div> */}

      <Layout className="full-width">
        <Row
          align="stretch"
          className="full-width"
          style={{ overflowY: "auto" }}
        >
          <Col span="4" style={{ backgroundColor: "#fff" }}>
            <SideNavigation />
          </Col>
          <Col span="20" className="p-2">
            <Row>
              <Col span="24" className="bg-white">
                {page[state.page] || (
                  <Result
                    status="warning"
                    title="You don't have permissions to view page"
                    extra=""
                  />
                )}
              </Col>
            </Row>

            <BackTop>
              <div style={style}>
                <IoArrowUp />
              </div>
            </BackTop>
          </Col>
        </Row>
      </Layout>
      <Footer />
    </Layout>
  );
};

export default AdminModule;
