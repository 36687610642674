import React, { useState, useEffect, useContext } from "react";

import {
  Row,
  Col,
  Input,
  Menu,
  Dropdown,
  Button,
  Typography,
  Radio,
  Space,
  Breadcrumb,
  Slider,
  Modal,
} from "antd";
import { ImGift } from "react-icons/im";
import { BiRupee } from "react-icons/bi";
import {BsInfoCircleFill} from "react-icons/bs";
import { IoLogOutOutline, IoCart } from "react-icons/io5";
import { Formik, Field, Form } from "formik";

// Custom Imports
import "./serverConfiguration.scss";
import { LoadPage } from "../../SharedFunctions";
import { Context } from "../../context";
import { BREADCRUMB } from "../../reducer/types";

const ServerConfiguration = () => {
  const [selectServer, setSelectServer] = useState("ser1");
  const [selectLocation, setSelectLocation] = useState("loc1");
  const [selectRAM, setSelectRAM] = useState("ram1");
  const [selectOS, setSelectOS] = useState("os1");
  const [selectRaid, setSelectRaid] = useState("raid1");
  const [selectHDD, setSelectHDD] = useState("hdd1");
  const [selectPubUplink, setSelectPubUplink] = useState("pubUl1");
  const [selectPrvUplink, setSelectPrvUplink] = useState("prvUl1");
  const [selectIpAddress, setSelectIpAddress] = useState("ip1");
  const [selectBandwidth, setSelectBandwidth] = useState("bw1");
  const [selectPriIp, setSelectPriIp] = useState("pIp1");
  const [selectSecIp, setSelectSecIp] = useState("sIp1");

  //Modal
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { state, dispatch } = useContext(Context);

  const breadNav = (
    <Breadcrumb>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("/", dispatch)}
        >
          Home
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>
        <Button
          type="link"
          className="p-0"
          onClick={() => LoadPage("dsOrder", dispatch)}
        >
          Dedicated Server
        </Button>
      </Breadcrumb.Item>
      <Breadcrumb.Item>Server Configuration</Breadcrumb.Item>
    </Breadcrumb>
  );

  const serverChange = (e) => {
    selectServer(e.target.value);
  };

  const locationChange = (e) => {
    setSelectLocation(e.target.value);
  };

  const ramChange = (e) => {
    setSelectRAM(e.target.value);
  };

  const osChange = (e) => {
    setSelectOS(e.target.value);
  };

  const closePormo = () => {
    setIsModalVisible(false);
  };

  //Show Promocode popup
  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    dispatch({
      type: BREADCRUMB,
      payload: {
        breadcrumb: breadNav,
      },
    });

    return () => {};
  }, []);

  return (
    <>
      {/* Page Header */}
      <Row>
        <Col span="24" className="px-2 pt-2 pb-5 mb-2">
          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose Chasis:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={serverChange} value={selectServer}>
                    <Space direction="vertical">
                      <Radio
                        value={"ser1"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-server">
                          <div className="config-server-details">
                            <div className="config-server-title full-width text-truncate">
                              Dell R220 / Intel Xeon E3-1231 v3 / 32 GB RAM /
                              2x2 TB SATA
                            </div>
                            <div className="config-server-info full-width text-truncate">
                              Intel Xeon E3-1231 v3 32 GB DDR3 RAM (up to 32GB)
                              2 x 2 TB SATA 3.5" Location: DFW2 (Dallas–Fort
                              Worth, TX, USA)
                            </div>
                          </div>
                          <div className="config-price-block">
                            <div className="config-price-text">
                              <BiRupee /> 9,99,999.00 <span> / Month</span>
                            </div>
                            <div className="price-convertion">
                              <span>USD => EUR</span>
                            </div>
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8"></Col>
          </Row>

          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose the location:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={locationChange} value={selectLocation}>
                    <Space direction="vertical">
                      <Radio
                        value={"loc1"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            AMS1 (Amazon Metropolitian Area, The Netherlands)
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 9,99,999.00 <span> / Month</span>
                            </div>
                            <div className="price-convertion">
                              <span>USD => EUR</span>
                            </div>
                          </div>
                        </div>
                      </Radio>
                      <Radio
                        value={"loc2"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            DFW1 (Dallas–Fort Worth, TX, USA)
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 9,99,999.00 <span> / Month</span>
                            </div>
                            <div className="price-convertion">
                              <span>USD => EUR</span>
                            </div>
                          </div>
                        </div>
                      </Radio>
                      <Radio
                        value={"loc3"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            LUX1 (Roost, Luxembourg)
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 99,999.00 <span> / Month</span>
                            </div>
                            <div className="price-convertion">
                              <span>USD => EUR</span>
                            </div>
                          </div>
                        </div>
                      </Radio>

                      <Radio
                        value={"loc4"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            MOW1 (UVAO, Moscow, Russia)
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 999.00 <span> / Month</span>
                            </div>
                            <div className="price-convertion">
                              <span>USD => EUR</span>
                            </div>
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8">
              <div className="server-configur-helper-info">
                <BsInfoCircleFill /> You can always configure your server with
                additional RAM. Please note that changes in the standard
                configurations of RAM and HDD can delay server deployment for 1
                business day and options may vary depending on the Location
              </div>
            </Col>
          </Row>

          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose RAM-Size:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={ramChange} value={selectRAM}>
                    <Space direction="vertical">
                      <Radio
                        value={"ram1"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            32 GB DDR3
                          </div>
                          <div className="config-location-price free">
                            FREE of Charge
                          </div>
                          <div className="price-convertion">
                            <span>USD => EUR</span>
                          </div>
                        </div>
                      </Radio>
                      <Radio
                        value={"ram2"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            64 GB DDR3
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 9,99,999.00 <span> / Month</span>
                            </div>
                          </div>
                          <div className="price-convertion">
                            <span>USD => EUR</span>
                          </div>
                        </div>
                      </Radio>
                      <Radio
                        value={"ram3"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            128 GB DDR3
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 9,99,999.00 <span> / Month</span>
                            </div>
                          </div>
                          <div className="price-convertion">
                            <span>USD => EUR</span>
                          </div>
                        </div>
                      </Radio>
                      <Radio
                        value={"ram4"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            256 GB DDR3
                          </div>
                          <div className="config-location-price">
                            <div className="config-price-text">
                              <BiRupee /> 9,99,999.00 <span> / Month</span>
                            </div>
                          </div>
                          <div className="price-convertion">
                            <span>USD => EUR</span>
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8">
              <div className="server-configur-helper-info">
                <BsInfoCircleFill /> You can always configure your server with
                additional RAM. Please note that changes in the standard
                configurations of RAM and HDD can delay server deployment for 1
                business day and options may vary depending on the Location
              </div>
            </Col>
          </Row>

          <Row className="server-config-section-row mb-2">
            <Col span="16">
              <div className="full-width server-config-section">
                <div className="server-config-section-title full-width">
                  Choose Operating System:
                </div>
                <div className="server-config-options full-width">
                  <Radio.Group onChange={osChange} value={selectOS}>
                    <Space direction="vertical">
                      <Radio
                        value={"os1"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            Without Operating System
                          </div>
                          <div className="config-location-price free">
                            FREE of Charge
                          </div>
                        </div>
                      </Radio>

                      <Radio
                        value={"os2"}
                        className="selectRadioBtn full-width"
                      >
                        <div className="config-radio-label select-location">
                          <div className="config-location-title text-truncate">
                            AlmaLinux 8 (64 bit)
                          </div>
                          <div className="config-location-price free">
                            FREE of Charge
                          </div>
                        </div>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </Col>
            <Col span="8"></Col>
          </Row>
        </Col>
      </Row>

      <div className="final-order-pricing-block">
        <div className="final-order-info">
          <div className="full-width order-info-title">
            Total Price:{" "}
            <span className="text-primary">
              <BiRupee /> 99,99,999.00
            </span>{" "}
            <span className="text-info multiply">*</span> + FREE Setup
          </div>
          <div className="full-width order-misc-info">
            1 server will be deployed in 24 hours
          </div>
        </div>
        <div className="final-order-actions-block d-flex align-items-center justify-content-end">
          <Button
            type="default"
            className="d-inline-flex align-items-center"
            onClick={showModal}
          >
            <ImGift />
            &nbsp; Apply promo code
          </Button>
          <Button type="default">Cancel</Button>
          <Button type="default" className="btn-primary">
            <IoCart />
            &nbsp; Add to Cart
          </Button>
        </div>
      </div>

      <Modal title="Apply promo code" visible={isModalVisible} footer={null}>
        <Row>
          <Col span="24">
            <Formik
              initialValues={{
                promoCode: "",
              }}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                alert(JSON.stringify(values, null, 2));
              }}
            >
              <Form>
                <ul className="form-list list-unstyled full-width mb-0">
                  <li>
                    <div className="each-form-item full-width">
                      <label htmlFor="firstName" className="form-label">
                        Promo code
                      </label>
                      <Field
                        id="firstName"
                        name="promoCode"
                        placeholder="Enter Promocode"
                        className="form-control"
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className="each-form-item mt-2 pt-2 border-top full-width actions-btns"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        type="default"
                        className="mx-15"
                        onClick={() => closePormo()}
                      >
                        Cancel
                      </Button>
                      <Button type="primary" className="btn-primary ml-1">
                        Apply
                      </Button>
                    </div>
                  </li>
                </ul>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ServerConfiguration;
